<template>
    <div>
        <slot>
            <ax-button @click="$emit('save')">
                {{ $t('shared.save') }}
            </ax-button>
        </slot>
    </div>
</template>

<script>
import AxButton from './AxButton'

export default {
    name: 'FormNavigation',
    components: {
        AxButton,
    },
}
</script>
